import React, { useState, useEffect } from 'react';
import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faFile, faUser, faHospital, faFileContract } from '@fortawesome/free-solid-svg-icons';
import emailjs from 'emailjs-com';

function App() {
  const [isOpen, setIsOpen] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [showSchedulePopup, setShowSchedulePopup] = useState(false);
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [feedback, setFeedback] = useState('');
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  // Preload the background image
  useEffect(() => {
    const img = new Image();
    img.src = `${process.env.PUBLIC_URL}/image601.png`;
    img.onload = () => setIsImageLoaded(true);
  }, []);

  // Email validation
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isValidName = (name) => name.trim().length > 0;

  const toggleMenu = () => setIsOpen(!isOpen);

  const togglePasswordPopup = () => {
    setShowPasswordPopup(!showPasswordPopup);
    setPassword('');
    setFeedback('');
  };

  const openSchedulePopup = () => {
    setShowPasswordPopup(false);
    setShowSchedulePopup(true);
    setFeedback('');  // Reset feedback when opening the schedule popup
  };
  

  const toggleSchedulePopup = () => {
    setShowSchedulePopup(!showSchedulePopup);
    setFeedback('');
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (!isValidName(name)) {
      setFeedback('Please enter a valid name.');
      return;
    }

    if (!isValidEmail(email)) {
      setFeedback('Please enter a valid email.');
      return;
    }

    emailjs
      .send(
        'service_ofn2sxj', // Replace with your service ID
        'template_iloty3l', // Replace with your template ID
        { reply_to: email, from_name: name, message: message },
        'rzhML3GOvOyNBsouG' // Replace with your user ID from EmailJS
      )
      .then(
        (result) => setFeedback('Your email has been sent. We will reach out shortly.'),
        (error) => alert('Failed to send email, please try again later.')
      );
  };

  return (
    <>
      {isImageLoaded && (
        <div className="App" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/image601.png)` }}>
          <nav className="navbar">
            <div className="nav-container">
              <div className="logo-container">
                <img src={`${process.env.PUBLIC_URL}/eden_logo.svg`} alt="Eden Logo" className="navbar-logo" />
              </div>
              <ul className={`navbar-links ${isOpen ? 'mobile' : ''}`}>
                <li>
                  <a href="#home" onClick={toggleMenu}>
                    <FontAwesomeIcon icon={faHome} className="icon" /> Home
                  </a>
                </li>
              </ul>
              <div className="hamburger-menu" onClick={toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </nav>

          <div className="content">
            <div className="glass-card">
              <div className="svg-main">
                <svg width="100px" height="100px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M21 14V10C21 9.44772 20.5523 9 20 9H15L15 4C15 3.44772 14.5523 3 14 3H10C9.44772 3 9 3.44772 9 4V9L4 9C3.44772 9 3 9.44772 3 10V14C3 14.5523 3.44772 15 4 15H9L9 20C9 20.5523 9.44771 21 10 21H14C14.5523 21 15 20.5523 15 20V15L20 15C20.5523 15 21 14.5523 21 14Z"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <p className="inter-glass-card-text-reim">Automating staff workflows within HIPAA reason.</p>
              <div className="icon-row">
              <FontAwesomeIcon icon={faFileContract} className="icon" title="Forms" />
              <FontAwesomeIcon icon={faUser} className="icon" title="People" />
                <FontAwesomeIcon icon={faHospital} className="icon" title="Hospitals" />

              </div>
              <div className="textss">
                <p className="inter-glass-card-text">From <strong className = 'bolded' >patient intake</strong> to <strong className = 'bolded' >triage requests</strong> to <strong className = 'bolded' >digital and physical forms</strong></p>

                <p className="inter-glass-card-text">Reach out for Early Access </p>
              </div>


              <div className="button-cont">
  <button className="button-86" role="button" onClick={togglePasswordPopup}>
    Enter Passcode
  </button>
</div>


            </div>
            <img src={`${process.env.PUBLIC_URL}/hipaa.png`} alt="HIPAA Compliant" className="hipaa-logo" />

          </div>

          {/* Password Popup */}
          {showPasswordPopup && (
            <div className="popup-overlay" onClick={togglePasswordPopup}>
              <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <h2>Enter Password</h2>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter password"
                />
                {feedback && <p className="feedback">{feedback}</p>}
                <button onClick={() => setFeedback('Incorrect Password')}>Submit</button>
                <button onClick={openSchedulePopup}>Request</button>
              </div>
            </div>
          )}

          {/* Schedule Popup */}
          {showSchedulePopup && (
            <div className="popup-overlay" onClick={toggleSchedulePopup}>
              <div className="popup-content" onClick={(e) => e.stopPropagation()}>
                <h2>Schedule Meeting</h2>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                  required
                />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  required
                />
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message"
                  required
                />
                <button onClick={sendEmail}>Submit</button>
                {feedback && <p className="feedback">{feedback}</p>}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default App;
